import { createAction } from 'redux-actions';

import { actionTypes } from './action-types';

export const appMount = createAction(actionTypes.appMount);


export const executeGA = createAction(actionTypes.executeGA);


export const setRenderStartTime = createAction(actionTypes.setRenderStartTime);

export const setCompletionCountWhenShownProgressModal = createAction(
  actionTypes.setCompletionCountWhenShownProgressModal
);
export const setShowMultipleProgressModals = createAction(
  actionTypes.setShowMultipleProgressModals
);


export const onlineStatusChange = createAction(actionTypes.onlineStatusChange);
export const serverStatusChange = createAction(actionTypes.serverStatusChange);

// TODO: re-evaluate this since /internal is no longer used.
// `hardGoTo` is used to hit the API server directly
// without going through /internal
// used for things like /signin and /signout
export const hardGoTo = createAction(actionTypes.hardGoTo);

export const submitComplete = createAction(actionTypes.submitComplete);
export const updateComplete = createAction(actionTypes.updateComplete);

export const saveChallenge = createAction(actionTypes.saveChallenge);
export const saveChallengeComplete = createAction(
  actionTypes.saveChallengeComplete
);


export const fetchUser = createAction(actionTypes.fetchUser);
export const fetchUserComplete = createAction(actionTypes.fetchUserComplete);
export const fetchUserError = createAction(actionTypes.fetchUserError);

export const loginAttempt = createAction(actionTypes.loginAttempt);
export const loginAttemptComplete = createAction(actionTypes.loginAttemptComplete);
export const loginAttemptError = createAction(actionTypes.loginAttemptError);

export const logout = createAction(actionTypes.logout);
export const logoutComplete = createAction(actionTypes.logoutComplete);
export const logoutError = createAction(actionTypes.logoutError);

export const updateAllChallengesInfo = createAction(
  actionTypes.updateAllChallengesInfo
);


export const fetchProfileForUser = createAction(
  actionTypes.fetchProfileForUser
);
export const fetchProfileForUserComplete = createAction(
  actionTypes.fetchProfileForUserComplete
);
export const fetchProfileForUserError = createAction(
  actionTypes.fetchProfileForUserError
);

export const reportUser = createAction(actionTypes.reportUser);
export const reportUserComplete = createAction(actionTypes.reportUserComplete);
export const reportUserError = createAction(actionTypes.reportUserError);

export const resetUserData = createAction(actionTypes.resetUserData);

export const showCert = createAction(actionTypes.showCert);
export const showCertComplete = createAction(actionTypes.showCertComplete);
export const showCertError = createAction(actionTypes.showCertError);

export const updateUserToken = createAction(actionTypes.updateUserToken);
export const deleteUserToken = createAction(actionTypes.deleteUserToken);
export const deleteUserTokenComplete = createAction(
  actionTypes.deleteUserTokenComplete
);

export const hideCodeAlly = createAction(actionTypes.hideCodeAlly);
export const showCodeAlly = createAction(actionTypes.showCodeAlly);
export const tryToShowCodeAlly = createAction(actionTypes.tryToShowCodeAlly);

export const startExam = createAction(actionTypes.startExam);
export const stopExam = createAction(actionTypes.stopExam);
export const clearExamResults = createAction(actionTypes.clearExamResults);

export const linkMsUsername = createAction(actionTypes.linkMsUsername);
export const unlinkMsUsername = createAction(actionTypes.unlinkMsUsername);
export const setMsUsername = createAction(actionTypes.setMsUsername);

export const submitSurvey = createAction(actionTypes.submitSurvey);
export const submitSurveyComplete = createAction(
  actionTypes.submitSurveyComplete
);

export const setIsProcessing = createAction(actionTypes.setIsProcessing);

export const closeSignoutModal = createAction(actionTypes.closeSignoutModal);
export const openSignoutModal = createAction(actionTypes.openSignoutModal);

export const closeResetPasswordModal = createAction(
  actionTypes.closeResetPasswordModal
);
export const openResetPasswordModal = createAction(
  actionTypes.openResetPasswordModal
);
