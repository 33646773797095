module.exports = [{
      plugin: require('../../node_modules/.pnpm/gatsby-plugin-manifest@5.13.1_gatsby@5.13.3_graphql@16.8.1/node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"freeCodeCamp","short_name":"fCC","start_url":"/","theme_color":"#0a0a23","background_color":"#fff","display":"minimal-ui","icon":"src/assets/images/square_puck.png","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"11889a8a16da817e295b5ac12006e195"},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../../node_modules/.pnpm/gatsby@5.13.3_babel-eslint@10.1.0_eslint-import-resolver-typescript@3.5.5_eslint-plugin-testi_hzymyyr2qysh5jcop74xf3q3zy/node_modules/gatsby/dist/internal-plugins/partytown/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
