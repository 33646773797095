import { createTypes, createAsyncTypes } from '../utils/create-types';

export const ns = 'app';

export const actionTypes = createTypes(
  [
    'appMount',
    'hardGoTo',
    'setRenderStartTime',
    'hideCodeAlly',
    'setCompletionCountWhenShownProgressModal',
    'setShowMultipleProgressModals',
    'openSignoutModal',
    'closeSignoutModal',
    'openResetPasswordModal',
    'closeResetPasswordModal',
    'onlineStatusChange',
    'serverStatusChange',
    'resetUserData',
    'tryToShowCodeAlly',
    'executeGA',
    'showCodeAlly',
    'startExam',
    'stopExam',
    'clearExamResults',
    'linkMsUsername',
    'unlinkMsUsername',
    'setMsUsername',
    'setIsProcessing',
    'submitComplete',
    'submitSurvey',
    'submitSurveyComplete',
    'updateComplete',
    'updateUserToken',
    'updateAllChallengesInfo',
    ...createAsyncTypes('fetchUser'),
    ...createAsyncTypes('fetchProfileForUser'),
    ...createAsyncTypes('loginAttempt'),
    ...createAsyncTypes('logout'),
    ...createAsyncTypes('acceptTerms'),
    ...createAsyncTypes('showCert'),
    ...createAsyncTypes('reportUser'),
    ...createAsyncTypes('deleteUserToken'),
    ...createAsyncTypes('saveChallenge')
  ],
  ns
);
