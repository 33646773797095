import cookies from 'browser-cookies';
import envData from '../../config/env.json';

import type {
  ChallengeFile,
  ChallengeFiles,
  CompletedChallenge,
  GenerateExamResponseWithData,
  SavedChallenge,
  SavedChallengeFile,
  SurveyResults,
  User
} from '../redux/prop-types';
import { da } from 'date-fns/locale';

const { apiLocation } = envData;

const base = envData.environment === 'digitalocean' ? apiLocation + '/api' : apiLocation;

const defaultOptions: RequestInit = {
  credentials: 'include'
};

// csrf_token is passed to the client as a cookie. The client must send
// this back as a header.
function getCSRFToken() {
  const token =
    typeof window !== 'undefined' ? cookies.get('csrf_token') : null;
  return token ?? '';
}

export interface ResponseWithData<T> {
  response: Response;
  data: T;
}

// TODO: Might want to handle flash messages as close to the request as possible
// to make use of the Response object (message, status, etc)
async function get<T>(path: string): Promise<ResponseWithData<T>> {
  const response = await fetch(`${base}${path}`, defaultOptions);

  return combineDataWithResponse(response);
}



async function combineDataWithResponse<T>(response: Response) {
  // console.log("in combine data with response", response)
  // console.log(response.headers.getSetCookie());
  const data = (await response.json()) as T;
  return { response, data };
}

export function post<T = void>(
  path: string,
  body: unknown
): Promise<ResponseWithData<T>> {
  return request('POST', path, body);
}

function put<T = void>(
  path: string,
  body?: unknown
): Promise<ResponseWithData<T>> {
  return request('PUT', path, body);
}

function deleteRequest<T = void>(
  path: string,
  body: unknown
): Promise<ResponseWithData<T>> {
  return request('DELETE', path, body);
}

async function request<T>(
  method: 'POST' | 'PUT' | 'DELETE',
  path: string,
  body: unknown
): Promise<ResponseWithData<T>> {
  const options: RequestInit = {
    ...defaultOptions,
    method,
    headers: {
      'CSRF-Token': getCSRFToken(),
      'Content-Type': 'application/json'
    },
    body: JSON.stringify(body)
  };

  // console.log("in request", options)

  const response = await fetch(`${base}${path}`, options);
  return combineDataWithResponse(response);
}

const newExpireTime = () => {
  let newDate = new Date();
  newDate.setTime(newDate.getTime() + 1000 * 60 * 60 * 24 * 900)
  return newDate
}

// check if we are in dev mode, if so, grab the cookies from the response
const checkAndSetCookies = (cookieArray: Array<string>) => {
  if (cookieArray) {
    cookies.defaults = {
      path: '/',
      expires: 900,
    }
    
      cookieArray.forEach((cooki) => {
        checkCookieHelper(cooki);
      });
    }
}

const checkCookieHelper = (cooki: string) => {
  
  let nameVal = cooki.split("; ")
  nameVal = nameVal[0].split("=")
  cookies.set(nameVal[0], nameVal[1])
  
}

/** GET **/

interface SessionUser {
  user?: { [username: string]: User };
}

type CompleteChallengeFromApi = {
  files: Array<Omit<ChallengeFile, 'fileKey'> & { key: string }>;
} & Omit<CompletedChallenge, 'challengeFiles'>;

type SavedChallengeFromApi = {
  files: Array<Omit<SavedChallengeFile, 'fileKey'> & { key: string }>;
} & Omit<SavedChallenge, 'challengeFiles'>;

type ApiSessionResponse = Omit<SessionUser, 'user'>;
type ApiUser = {
  user: {
    [username: string]: Omit<
      User,
      'completedChallenges' & 'savedChallenges'
    > & {
      completedChallenges?: CompleteChallengeFromApi[];
      savedChallenges?: SavedChallengeFromApi[];
    };
  };
  result?: string;
};

type UserResponse = {
  user: { [username: string]: User } | Record<string, never>;
  result: string | undefined;
};

function parseApiResponseToClientUser(data: ApiUser): UserResponse {

    const userData = data.user?.[data?.result ?? ''];
    let completedChallenges: CompletedChallenge[] = [];
    let savedChallenges: SavedChallenge[] = [];
    if (userData) {
      completedChallenges = mapFilesToChallengeFiles(
        userData.completedChallenges
      );
      savedChallenges = mapFilesToChallengeFiles(userData.savedChallenges);
    }
    return {
      user: {
        [data.result ?? '']: { ...userData, completedChallenges, savedChallenges }
      },
      result: data.result
    };
  }
  

// TODO: this at least needs a few aliases so it's human readable
// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export function mapFilesToChallengeFiles<File, Rest>(
  fileContainer: ({ files: (File & { key: string })[] } & Rest)[] = []
) {
  return fileContainer.map(({ files, ...rest }) => ({
    ...rest,
    challengeFiles: mapKeyToFileKey(files)
  }));
}

function mapKeyToFileKey<K>(
  files: (K & { key: string })[]
): (Omit<K, 'key'> & { fileKey: string })[] {
  return files.map(({ key, ...rest }) => ({ ...rest, fileKey: key }));
}

export function getSessionUser(): Promise<ResponseWithData<SessionUser>> {
  const responseWithData: Promise<
    ResponseWithData<ApiUser & ApiSessionResponse>
  > = get('/user/get-session-user');
  // TODO: Once DB is migrated, no longer need to parse `files` -> `challengeFiles` etc.
  return responseWithData.then(({ response, data }) => {
    const { result, user } = parseApiResponseToClientUser(data);

    return {
     response,
      data: {
         result,
        user
      }
    };
  });
}

interface PersevereAuth {
  idNum: string;
}
/**
 * Attempt Login for a student.
 * 
 * @param idNum ID number of the student, IP is sent with requests automatically, so the server can determine if the request is coming from the correct classroom for said ID number.
 * @returns data with user object and result string.
 */

export function attemptLogin(idNum: string, password: string): Promise<ResponseWithData<any>> {
  const responseWithData: Promise<ResponseWithData<any>> = request('POST', '/signin', {email: idNum, password: password});
  return responseWithData.then(({ response, data }) => {
    if(data.user){

      const { result, user } = parseApiResponseToClientUser(data.user);
    }
    // Add cookies from response to browser
    if(response.status == 401){
      // alert("login failed")
      return  {
        response,
         data: {
         }
       };
    }
    if (data.setCookieHeaders) {
    typeof data.setCookieHeaders == Array ? checkAndSetCookies(data.setCookieHeaders) : checkCookieHelper(data.setCookieHeaders);
    }
    else if (data.jwt_access_token) {
      cookies.set('jwt_access_token', data.jwt_access_token, {expires: newExpireTime()})
    }
    return getSessionUser();
  });
}

export function attemptLogout(): Promise<Boolean> {
  const responseWithData: Promise<ResponseWithData<any>> = get('/signout');
  return responseWithData.then(({ response, data }) => {
    if (response.status == 200) {
      cookies.erase('jwt_access_token');
      return true
    }
    else {
      return false
    }
  });
}

// PASSWORD RESETS

export function attemptPasswordChange({email, password, oldPassword}: {email: string, password: string, oldPassword: string}): Promise<number> {
  const responseWithData: Promise<ResponseWithData<any>> = request('POST', '/change-password', {email: email, newPassword: password, password: oldPassword});
  return responseWithData.then(({ response, data }) => {
    return response.status;
  }
  );
}


// STAFF TOOLS

export function adminGetStudents(query: string): Promise<ResponseWithData<any> | {data: any;}> {
  const responseWithData: Promise<ResponseWithData<any>> = get(`/admin/students?${query}`);
  return responseWithData.then(({ response, data }) => {
    return {data}
  });
}

export function adminGetStaff(query: string): Promise<ResponseWithData<any> | {data: any;}> {
  if (query === undefined) {
    query = "";
  }
  const responseWithData: Promise<ResponseWithData<any>> = get(`/admin/staff?${query}`);
  return responseWithData.then(({ response, data }) => {
    return {data}
  });
}


export function adminDeactivateStudent(students: string[]): Promise<boolean> {

  const responseWithData: Promise<ResponseWithData<any>> = request('POST', '/admin/deactivate-user', {students: students});
  return responseWithData.then(({ response, data }) => {
    if (response.status == 200) {
      return true
    }
    else {
      return false
    }
  }
  );
}

export function adminActivateStudent(email: string): Promise<boolean> {
  const responseWithData: Promise<ResponseWithData<any>> = request('POST', '/admin/activate-user', {email: email});
  return responseWithData.then(({ response, data }) => {
    if (response.status == 200) {
      return true
    }
    else {
      return false
    }
  }
  );
}

export function adminResetPassword(users: string[]): Promise<boolean> {
  const responseWithData: Promise<ResponseWithData<any>> = request('POST', '/admin/reset-password', {students: users});
  return responseWithData.then(({ response, data }) => {
    if (response.status == 200) {
      return true
    }
    else {
      return false
    }
  }
  );
}

export function adminChangeTeacherClassroom(email: string, type: 'add' | 'remove', classrooms: Array<string>): Promise<boolean> {
  const responseWithData: Promise<ResponseWithData<any>> = request('POST', '/admin/changeteacherclassroom', {email: email, type: type, classrooms: classrooms});
  return responseWithData.then(({ response, data }) => {
    if (response.status == 200) {
      return true
    }
    else {
      return false
    }
  }
  );
}





export interface Student {
  name: string;
  email: string;
}

export function adminCreateStudents(facility: string, students: Array<Student>): Promise<boolean> {
  const responseWithData: Promise<ResponseWithData<any>> = request('POST', '/add-users', {facility: facility, students: students});
  return responseWithData.then(({ response, data }) => {
    if (response.status == 200) {
      return true
    }
    else {
      return false
    }
  }
  );
}

export function adminDeleteStudents(students: Array<string>): Promise<boolean> {
  const responseWithData: Promise<ResponseWithData<any>> = request('POST', '/admin/delete', {students: students});
  return responseWithData.then(({ response, data }) => {
    if (response.status == 200) {
      return true
    }
    else {
      return false
    }
  }
  );
}

export interface AdminUser {
  username: string;
  email: string;
  role: string;
  classrooms?: string[];
}

export function adminCreateUser(users: AdminUser[]): Promise<boolean> {
  const responseWithData: Promise<ResponseWithData<any>> = request('POST', '/create-user', users);
  return responseWithData.then(({ response, data }) => {
    if (response.status == 200) {
      return true
    }
    else {
      return false
    }
  }
  );
}

export function getServerStatus(): Promise<ResponseWithData<any>> {

  const startTimes = new Date().getTime();
  const responseWithData: Promise<ResponseWithData<any>> = get('/status');
  const endTimes = new Date().getTime();
  return responseWithData.then(({ response, data }) => {

    data = {...data, responseTime: endTimes - startTimes}
    return { response, data };
  });
}

interface UpdateObject {
  email?: string;
  name?: string;
  role?: string;
  classrooms?: string[];
  facility?: string;
  isDeactivated?: boolean;
}

export function adminEditUser(email: string, password: string, update: UpdateObject): Promise<ResponseWithData<any>> {
  const responseWithData: Promise<ResponseWithData<any>> = request('POST', '/admin/update-user', {password: password, email: email, update: update});
  return responseWithData.then(({ response, data }) => {
    return {response, data}
  });
}

type UserProfileResponse = {
  entities: Omit<UserResponse, 'result'>;
  result: string | undefined;
};
export function getUserProfile(
  username: string
): Promise<ResponseWithData<UserProfileResponse>> {
  const responseWithData = get<{ entities?: ApiUser; result?: string }>(
    `/api/users/get-public-profile?username=${username}`
  );
  return responseWithData.then(({ response, data }) => {
    const { result, user } = parseApiResponseToClientUser({
      user: data.entities?.user ?? {},
      result: data.result
    });
    return {
      response,
      data: {
        entities: { user },
        result
      }
    };
  });
}

interface Cert {
  certTitle: string;
  username: string;
  date: Date;
  completionTime: string;
}
export function getShowCert(
  username: string,
  certSlug: string
): Promise<ResponseWithData<Cert>> {
  return get(`/certificate/showCert/${username}/${certSlug}`);
}

export function getUsernameExists(
  username: string
): Promise<ResponseWithData<boolean>> {
  return get(`/api/users/exists?username=${username}`);
}

export function getGenerateExam(
  challengeId: string
): Promise<GenerateExamResponseWithData> {
  return get(`/exam/${challengeId}`);
}

/** POST **/

interface Report {
  username: string;
  reportDescription: string;
}
export function postReportUser(body: Report): Promise<ResponseWithData<void>> {
  return post('/user/report-user', body);
}

// Both are called without a payload in danger-zone-saga,
// which suggests both are sent without any body
// TODO: Convert to DELETE
export function postDeleteAccount(): Promise<ResponseWithData<void>> {
  return post('/account/delete', {});
}

export function postResetProgress(): Promise<ResponseWithData<void>> {
  return post('/account/reset-progress', {});
}

export function postUserToken(): Promise<ResponseWithData<void>> {
  return post('/user/user-token', {});
}

export function postMsUsername(body: {
  msTranscriptUrl: string;
}): Promise<ResponseWithData<void>> {
  return post('/user/ms-username', body);
}

export function postSaveChallenge(body: {
  id: string;
  files: ChallengeFiles;
}): Promise<ResponseWithData<void>> {
  return post('/save-challenge', body);
}

export function postSubmitSurvey(body: {
  surveyResults: SurveyResults;
}): Promise<ResponseWithData<void>> {
  return post('/user/submit-survey', body);
}

/** PUT **/

interface MyAbout {
  name: string;
  location: string;
  about: string;
  picture: string;
}
export function putUpdateMyAbout(
  values: MyAbout
): Promise<ResponseWithData<void>> {
  return put('/update-my-about', { ...values });
}

export function putUpdateMyUsername(
  username: string
): Promise<ResponseWithData<void>> {
  return put('/update-my-username', { username });
}

export function putUpdateMyProfileUI(
  profileUI: User['profileUI']
): Promise<ResponseWithData<void>> {
  return put('/update-my-profileui', { profileUI });
}

export function putUpdateMySocials(
  update: Record<string, string>
): Promise<ResponseWithData<void>> {
  return put('/update-my-socials', update);
}

export function putUpdateMyTheme(
  update: Record<string, string>
): Promise<ResponseWithData<void>> {
  return put('/update-my-theme', update);
}

export function putUpdateMyKeyboardShortcuts(
  update: Record<string, string>
): Promise<ResponseWithData<void>> {
  return put('/update-my-keyboard-shortcuts', update);
}

export function putUpdateMyHonesty(
  update: Record<string, string>
): Promise<ResponseWithData<void>> {
  return put('/update-my-honesty', update);
}

export function putUpdateMyQuincyEmail(
  update: Record<string, string>
): Promise<ResponseWithData<void>> {
  return put('/update-my-quincy-email', update);
}

export function putUpdateMyPortfolio(
  update: Record<string, string>
): Promise<ResponseWithData<void>> {
  return put('/update-my-portfolio', update);
}

export function putUserAcceptsTerms(
  quincyEmails: boolean
): Promise<ResponseWithData<void>> {
  return put('/update-privacy-terms', { quincyEmails });
}

export function putUserUpdateEmail(
  email: string
): Promise<ResponseWithData<void>> {
  return put('/update-my-email', { email });
}

export function putVerifyCert(
  certSlug: string
): Promise<ResponseWithData<void>> {
  return put('/certificate/verify', { certSlug });
}

/** DELETE **/
export function deleteUserToken(): Promise<ResponseWithData<void>> {
  return deleteRequest('/user/user-token', {});
}

export function deleteMsUsername(): Promise<ResponseWithData<void>> {
  return deleteRequest('/user/ms-username', {});
}
