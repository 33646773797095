import Loadable from '@loadable/component';
import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useMediaQuery } from 'react-responsive';
import { isLanding } from '../../../utils/path-parsers';
import { Link, SkeletonSprite } from '../../helpers';
import { SEARCH_EXPOSED_WIDTH } from '../../../../config/misc';
import {
  faCheckSquare,
  faSquare,
  faExternalLinkAlt,
  faCircleHalfStroke
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import MenuButton from './menu-button';
import NavLinks, { type NavLinksProps } from './nav-links';
import NavLogo from './nav-logo';
import './universal-nav.css';
import AuthOrProfile from './auth-or-profile';
import LanguageList from './language-list';
import { type ThemeProps, Themes } from '../../settings/theme';
import { openSignoutModal } from '../../../redux/actions';
import { updateMyTheme } from '../../../redux/settings/actions';
import { User } from '../../../redux/prop-types';
import { createSelector } from 'reselect';
import { isSignedInSelector, userRoleSelector, userSelector } from '../../../redux/selectors';
import { connect } from 'react-redux';
import { navigate } from 'gatsby-link';
import { useLocation } from '@reach/router';
import { Box, Grid } from '@mui/material';



interface UniversalNavProps extends NavLinksProps {
  fetchState: { pending: boolean };
  searchBarRef?: React.RefObject<HTMLDivElement>;
  isSignedIn: boolean;
  user: User;
  role: string;
};
const mapDispatchToProps = {
  toggleNightMode: (theme: Themes) => updateMyTheme({ theme }),
  openSignoutModal
};
const mapStateToProps = createSelector(
  isSignedInSelector,
  userSelector,
  userRoleSelector,
  (isSignedIn: boolean, user: User, role: string) => ({
    isSignedIn,
    user,
    role
  })
)

const toggleTheme = (
  currentTheme = Themes.Default,
  toggleNightMode: typeof updateMyTheme
) => {
  toggleNightMode(
    currentTheme === Themes.Night ? Themes.Default : Themes.Night
  );
};



const UniversalNav = ({
  displayMenu,
  showMenu,
  hideMenu,
  menuButtonRef,
  searchBarRef,
  user,
  fetchState,
  toggleNightMode,
  isSignedIn,
  role
}: UniversalNavProps): JSX.Element => {
  const { pending } = fetchState;
  const { t } = useTranslation();
  const location = useLocation();

  // useEffect(() => {
  //   console.log('user: ', user, 'needsPasswordReset: ', user.needsPasswordReset);
  //   // check if user needsPasswordReset. If so, redirect to reset-password page
  //   if (user && user.needsPasswordReset && location.pathname !== '/reset-password') {
  //       navigate('/reset-password');
  //   }
  // }, [user]);

  const NavButtons = () => (
    <>
        <button className="exposed-button-nav"><FontAwesomeIcon icon={faCircleHalfStroke} className='universal-nav-theme-selector' onClick={() => toggleTheme(user?.theme, toggleNightMode)} /></button>
        {role !== 'student' &&
          <button className="exposed-button-nav">
            <Link to='/admin'>
              <Box component="span" sx={{ display: { xs: 'none', md: 'block' } }}>
                Admin Area
              </Box>
              <Box component="span" sx={{ display: { xs: 'block', md: 'none' } }}>
                Admin
              </Box>
            </Link>
          </button>}
        <button
          aria-expanded={displayMenu}
          className='exposed-button-nav'
          id='toggle-button-nav'
          data-playwright-test-label='header-menu-button'
        >
          <Link className='menu-btn-txt' to='/learn'>
            <Box component="span" sx={{ display: { xs: 'none', sm: 'block' } }}>
              Courses
            </Box>
            <Box component="span" sx={{ display: { xs: 'block', sm: 'none' } }}>
              Learn
            </Box>
          </Link>
        </button>
        </>
  );

  return (
    <nav
      aria-label={t('aria.primary-nav')}
      className='universal-nav'
      id='universal-nav'
      data-playwright-test-label='header-universal-nav'
    >
      <Link
        className='universal-nav-logo'
        id='universal-nav-logo'
        to='/learn'
        data-playwright-test-label='header-universal-nav-logo'
      >
        <NavLogo />
      </Link>
      <div className='universal-nav-right main-nav'>
        {pending ? (
          <div className='nav-skeleton'>
            <SkeletonSprite />
          </div>
        ) : isSignedIn && (
          <>
            <MenuButton
              displayMenu={displayMenu}
              showMenu={showMenu}
              hideMenu={hideMenu}
              innerRef={menuButtonRef}
            />
            <NavLinks
              displayMenu={displayMenu}
              hideMenu={hideMenu}
              menuButtonRef={menuButtonRef}
              showMenu={showMenu}
              user={user}
            />
          </>
        )}
      </div>
    </nav>
  );
};

UniversalNav.displayName = 'UniversalNav';
export default connect(mapStateToProps, mapDispatchToProps)(UniversalNav)
