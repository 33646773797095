import { all } from 'redux-saga/effects';

import { sagas as challengeSagas } from '../templates/Challenges/redux';
import errorSagas from './error-saga';
import { sagas as settingsSagas } from './settings';
import { sagas as appSagas } from '.';
import { sagas as adminSagas } from '../templates/StaffTools/redux';

export default function* rootSaga() {
  yield all([...errorSagas, ...appSagas, ...challengeSagas, ...settingsSagas, ...adminSagas]);
}
