import {call, put, takeEvery } from 'redux-saga/effects';
import { adminGetStaff, adminGetStudents, getServerStatus } from '../../../utils/ajax';
import {
    fetchStudents,
    fetchStudentsComplete,
    fetchStudentsError,
    fetchServerStatus,
    fetchServerStatusComplete,
    fetchServerStatusError,
    fetchStaff,
    fetchStaffComplete,
    fetchStaffError
} from './actions';

function* attemptFetchStudents({ payload }) {
    let query = '';
    // if (queryObj) {
    //     query = '?';
    //     for (let key in queryObj) {
    //         query += `${key}=${queryObj[key]}&`;
    //     }
    //     query = query.slice(0, -1);
    // }
    try {
        const { data } = yield call(adminGetStudents, query);
        yield put(fetchStudentsComplete({data}));
    } catch (e) {
        yield put(fetchStudentsError(e));
    }
}

function* attemptFetchServerStatus() {
    try {
        const { data } = yield call(getServerStatus);
        yield put(fetchServerStatusComplete(data));
    } catch (e) {
        yield put(fetchServerStatusError(e));
    }
}

function* attemptFetchStaff({ payload }) {
    try {
        const { data } = yield call(adminGetStaff);
        yield put(fetchStaffComplete(data));
    } catch (e) {
        yield put(fetchStaffError(e));
    }
}

export function createFetchItemsSaga(types) {
    return [
        takeEvery(types.fetchStudents, attemptFetchStudents),
        takeEvery(types.fetchServerStatus, attemptFetchServerStatus),
        takeEvery(types.fetchStaff, attemptFetchStaff)
    ];
}