import React from 'react';
import {
    Modal,
    Button,
    FormControl,
    TextField,
    Typography,
    Grid,
    Box
} from '@mui/material';
import { bindActionCreators, Dispatch } from 'redux';
import { createSelector } from 'reselect';
import { connect } from 'react-redux';
import { User } from '../../redux/prop-types';
import { closeResetPasswordModal, fetchUser } from '../../redux/actions';
import { isResetPasswordModalOpenSelector, userSelector } from '../../redux/selectors';
import { attemptPasswordChange } from '../../utils/ajax';
import MuiTheme from '../../templates/StaffTools/mui-theme';

const mapStateToProps = createSelector(
    isResetPasswordModalOpenSelector,
    userSelector,
    (show: boolean, user: User) => ({
        show,
        user
    })
);

const mapDispatchToProps = (dispatch: Dispatch) =>
    bindActionCreators(
        {
            closeResetPasswordModal,
            fetchUser
        },
        dispatch
    );

type ResetPasswordModalProps = {
    closeResetPasswordModal: () => void;
    fetchUser: () => void;
    show: boolean;
    user: User;
    type?: boolean;
};

function ResetPasswordModal(props: ResetPasswordModalProps): JSX.Element {
    const { show, closeResetPasswordModal, fetchUser, user } = props;
    let type = user.needsPasswordReset;
    const [oldPassword, setOldPassword] = React.useState('');
    const [newPassword, setNewPassword] = React.useState('');
    const [confirmPassword, setConfirmPassword] = React.useState('');
    const handleModalHide = () => {
        closeResetPasswordModal();
    };
    const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        switch (true) {
            case newPassword !== confirmPassword:
                alert('Passwords do not match!');
                break;
            case newPassword.length < 8:
                alert('Password must be at least 8 characters long!');
                break;
            case !/[A-Z]/.test(newPassword):
                alert('Password must contain at least one uppercase character!');
                break;
            case !/[0-9]/.test(newPassword):
                alert('Password must contain at least one number!');
                break;
            case !/[!@#$%^&*]/.test(newPassword):
                alert('Password must contain at least one special character!');
                break;
            default:
                handleResetPassword(newPassword);
                break;
        }
    };

    const handleResetPassword = (newPassword: string) => {
        attemptPasswordChange({ email: user.email, password: newPassword, oldPassword: oldPassword })
            .then((res) => {
                if (res == 200) {
                    fetchUser();
                    alert('Password reset successfully.');
                    handleModalHide();
                } 
                else if (res == 401) {
                    alert('Old password is incorrect. Please try again.');
                }
                else {
                    alert('Password reset failed.');
                }
            }
            ).catch((err) => {
                console.error(err);
                alert('Password reset failed. Are you sure that you are in need of a password reset?');
            });
    };

    const ResetPasswordOwnChoice = () => {
        return (
            <Typography variant='body1'>You have requested a password reset. Please enter your new password below and confirm it.</Typography>
        )
    }

    const ResetPasswordAdminForced = () => {
        return (
            <Typography variant='body1'>Your account appears to be in need of a password reset. Please enter your new password below and confirm it.
            </Typography>
        )
    }

    const handleCloseCheck = () => {
        // we don't want to close the modal if the user is forced to reset their password
        if (type) {
            return;
        }
        handleModalHide();
    }

    return (
        <MuiTheme>
        <Modal
            className='text-center'
            open={show}
            sx={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
            }}
            onClose={handleCloseCheck}
        >
            <Box component='form' noValidate autoComplete='off' onSubmit={(e) => handleSubmit(e)} className='modal-pcc'>
                <Grid container spacing={2}>
                    <Grid item xs={12}>
                        {type ?  <ResetPasswordAdminForced /> : <ResetPasswordOwnChoice />}
                        <br />
                        <Typography variant='body1'>Password must be at least 8 characters long and contain at least one uppercase letter, one number, and one special character.</Typography>
                    </Grid>
                    {type ? null : <Grid item xs={12}>
                        <FormControl fullWidth={true}> 
                            <TextField
                                value={oldPassword}
                                onChange={(e) => setOldPassword(e.target.value)}
                                className='form-mui'
                                id='oldPassword'
                                label='Old Password'
                                type='password'
                                InputLabelProps={{
                                    className: 'form-mui-label'
                                }}
                            />
                            </FormControl>
                        </Grid>}
                    <Grid item xs={12}>
                        
                        <FormControl fullWidth={true}>
                            

                            <TextField
                                value={newPassword}
                                onChange={(e) => setNewPassword(e.target.value)}
                                className='form-mui'
                                id='newPassword'
                                label='New Password'
                                type='password'
                                color='primary'
                                InputLabelProps={{
                                    className: 'form-mui-label'
                                }}
                            />
                        </FormControl>
                    </Grid>
                    <Grid item xs={12}>
                        <FormControl fullWidth={true}>
                            <TextField
                                value={confirmPassword}
                                onChange={(e) => setConfirmPassword(e.target.value)}
                                className='form-mui'
                                id='confirmPassword'
                                label='Confirm Password'
                                type='password'
                                InputLabelProps={{
                                    className: 'form-mui-label'
                                }}
                            />
                        </FormControl>
                    </Grid>
                    <Grid item xs={12}>
                    <button type="submit" style={{
                        padding: '1vmin 2vmin',
                    }}>Reset Password</button>
                    </Grid>
                </Grid>
            </Box>
        </Modal>
        </MuiTheme>
    );
}

export default connect(mapStateToProps, mapDispatchToProps)(ResetPasswordModal);