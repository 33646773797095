import { handleActions } from "redux-actions";
import { actionTypes, ns } from "./action-types";
import { createFetchItemsSaga } from "./fetch-items-saga";


export { ns };

const defaultFetchState = {
    pending: true,
    complete: false,
    errored: false,
    error: null
};

/*
* Example of Status Object
* {
*   "serverstats": {
*   "serverPing": <ping in ms>,
*   "serverUptime": <uptime in ms>,
*   "currentIP": <ip>,
*   "serverVersion": <version>,
*   "serverLastDeploy": <unixTimeStamp>,
*   }
*
*   "serverAnnouncements": [
*       {
*           "id": 1,
*           "title": "Server Maintenance",
*           "message": "We will be performing maintenance on the server at 10:00 PM EST",
*           "relevantBranches": ["all", "production", "testing"],
*           "dateTime": unixTimeStamp
*       }
*   ],
*
*   "serverIssues": [
*       {
*           "id": 1,
*           "title": "Server Maintenance",
*           "by": "user-id",
*           "message": "We will be performing maintenance on the server at 10:00 PM EST",
*           "dateTime": unixTimeStamp
*           "status": "posted" | "sentOut" | "resolved-noFix" | "resolved-fixed" | "noSend"
*       }
*
* }
*/


const initialState = {
    currentSection: 'home',
    lastRefreshed: Date.now(),
    statusRefreshState: {
        ...defaultFetchState
    },
    status: {},
    students: [],
    studentsFetchState: {
        ...defaultFetchState
    },
    staff: [],
    staffFetchState: {
        ...defaultFetchState
    },
    showUserDetailModal: false,
    selectedUser: null
};



export const reducer = handleActions(
    {
        [actionTypes.changeSection]: (state, { payload }) => ({
            ...state,
            currentSection: payload
        }),
        [actionTypes.fetchStudents]: (state, {payload}) => {
            return (
            {
            ...state,
            studentsFetchState: {
                ...state.studentsFetchState,
                pending: true
            }
        })},
        [actionTypes.fetchStudentsComplete]: (state, { payload }) => ({
            ...state,
            students: payload.data,
            studentsFetchState: {
                ...state.studentsFetchState,
                pending: false,
                complete: true
            }
        }),
        [actionTypes.fetchStudentsError]: (state, { payload }) => ({
            ...state,
            studentsFetchState: {
                ...state.studentsFetchState,
                pending: false,
                errored: true,
                error: payload
            }
        }),
        [actionTypes.fetchServerStatus]: state => ({
            ...state,
            statusRefreshState: {
                ...state.statusRefreshState,
                pending: true
            }
        }),
        [actionTypes.fetchServerStatusComplete]: (state, { payload }) => ({
            ...state,
            status: payload,
            lastRefreshed: Date.now(),
            statusRefreshState: {
                ...state.statusRefreshState,
                pending: false,
                complete: true
            }
        }),
        [actionTypes.fetchServerStatusError]: (state, { payload }) => ({
            ...state,
            statusRefreshState: {
                ...state.statusRefreshState,
                pending: false,
                errored: true,
                error: payload
            }
        }),
        [actionTypes.fetchStaff]: (state, {payload}) => {
            return (
            {
            ...state,
            staffFetchState: {
                ...state.staffFetchState,
                pending: true
            }
        })},
        [actionTypes.fetchStaffComplete]: (state, { payload }) => {
            return ({
            ...state,
            staff: payload,
            staffFetchState: {
                ...state.staffFetchState,
                pending: false,
                complete: true
            }
        })},
        [actionTypes.fetchStaffError]: (state, { payload }) => ({
            ...state,
            staffFetchState: {
                ...state.staffFetchState,
                pending: false,
                errored: true,
                error: payload
            }
        }),
        [actionTypes.openUserDetailModal]: state => ({
            ...state,
            showUserDetailModal: true
        }),
        [actionTypes.closeUserDetailModal]: state => ({
            ...state,
            showUserDetailModal: false
        }),
        [actionTypes.selectedUserDetail]: (state, { payload }) => ({
            ...state,
            selectedUser: payload
        })
    }, initialState
);

export const sagas = [
    ...createFetchItemsSaga(actionTypes)
];


export const currentSectionSelector = state => state[ns].currentSection;
export const lastRefreshedSelector = state => state[ns].lastRefreshed;
export const statusRefreshStateSelector = state => state[ns].statusRefreshState;
export const statusSelector = state => state[ns].status;
export const studentsExistSelector = state => (state[ns].students.length > 0 && state[ns].studentsFetchState.complete);
export const studentsSelector = state => state[ns].students;
export const studentsFetchStateSelector = state => state[ns].studentsFetchState;

export const staffExistSelector = state => (state[ns].staff.length > 0 && state[ns].staffFetchState.complete);
export const staffSelector = state => state[ns].staff;
export const staffFetchStateSelector = state => state[ns].staffFetchState;
export const isUserDetailModalOpenSelector = state => state[ns].showUserDetailModal;
export const selectedUserSelector = state => state[ns].selectedUser;
