import React, { ReactNode, useEffect } from 'react';
import Helmet from 'react-helmet';
import { useTranslation, withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { bindActionCreators, Dispatch } from 'redux';
import { createSelector } from 'reselect';
import { useStaticQuery, graphql, navigate } from 'gatsby';
import type { PageProps } from 'gatsby';
import muiTheme from '../../templates/StaffTools/mui-theme';

import latoBoldURL from '../../../static/fonts/Open_Sans/OpenSans-Bold.ttf';
import latoLightURL from '../../../static/fonts/Open_Sans/OpenSans-Light.ttf';
import latoRegularURL from '../../../static/fonts/Open_Sans/OpenSans-Regular.ttf';
import hackZeroSlashBoldURL from '../../../static/fonts/Poppins/Poppins-Bold.ttf';
import hackZeroSlashItalicURL from '../../../static/fonts/Poppins/Poppins-Italic.ttf';
import hackZeroSlashRegularURL from '../../../static/fonts/Poppins/Poppins-Regular.ttf';

import { isBrowser } from '../../../utils';
import {
  fetchUser,
  onlineStatusChange,
  serverStatusChange,
  updateAllChallengesInfo,
  openResetPasswordModal
} from '../../redux/actions';
import {
  isSignedInSelector,
  examInProgressSelector,
  userSelector,
  isOnlineSelector,
  isServerOnlineSelector,
  showCodeAllySelector,
  userFetchStateSelector,
  isResetPasswordModalOpenSelector
} from '../../redux/selectors';

import {
  UserFetchState,
  User,
  AllChallengeNode,
  CertificateNode
} from '../../redux/prop-types';
import BreadCrumb from '../../templates/Challenges/components/bread-crumb';
import Flash from '../Flash';
import { flashMessageSelector, removeFlashMessage } from '../Flash/redux';
import SignoutModal from '../signout-modal';
import ResetPasswordModal from '../resetpass-modal';
import StagingWarningModal from '../staging-warning-modal';
import Footer from '../Footer';
import Header from '../Header';
import OfflineWarning from '../OfflineWarning';
import { Loader } from '../helpers';
import envData from '../../../config/env.json';
import { useLocation } from '@reach/router';
import cookies from 'browser-cookies';

// preload common fonts
import './fonts.css';
import './global.css';
import './variables.css';
import './rtl-layout.css';

const mapStateToProps = createSelector(
  isSignedInSelector,
  examInProgressSelector,
  flashMessageSelector,
  isOnlineSelector,
  isServerOnlineSelector,
  userFetchStateSelector,
  showCodeAllySelector,
  userSelector,
  isResetPasswordModalOpenSelector,
  (
    isSignedIn,
    examInProgress: boolean,
    flashMessage,
    isOnline: boolean,
    isServerOnline: boolean,
    fetchState: UserFetchState,
    showCodeAlly: boolean,
    user: User,
    isResetPasswordModalOpen: boolean
  ) => ({
    isSignedIn,
    examInProgress,
    flashMessage,
    hasMessage: !!flashMessage.message,
    isOnline,
    isServerOnline,
    fetchState,
    theme: user.theme,
    showCodeAlly,
    user,
    isResetPasswordModalOpen
  })
);

type StateProps = ReturnType<typeof mapStateToProps>;

const mapDispatchToProps = (dispatch: Dispatch) =>
  bindActionCreators(
    {
      fetchUser,
      removeFlashMessage,
      onlineStatusChange,
      serverStatusChange,
      updateAllChallengesInfo,
      openResetPasswordModal
    },
    dispatch
  );

type DispatchProps = ReturnType<typeof mapDispatchToProps>;

interface DefaultLayoutProps extends StateProps, DispatchProps {
  children: ReactNode;
  pathname: string;
  showFooter?: boolean;
  isChallenge?: boolean;
  block?: string;
  examInProgress: boolean;
  showCodeAlly: boolean;
  superBlock?: string;
  openResetPasswordModal: () => void;
}

const getSystemTheme = () =>
  `${
    window.matchMedia('(prefers-color-scheme: dark)').matches === true
      ? 'dark-palette'
      : 'light-palette'
  }`;

// const checkLocation = (pathname: string) => {
//   if (pathname === '/') return false;
//   const allowedPaths = [
//     '/',
//     '/admin'
//   ];
//   return !allowedPaths.includes(pathname);
// }

function DefaultLayout({
  children,
  hasMessage,
  examInProgress,
  fetchState,
  flashMessage,
  isOnline,
  isServerOnline,
  isSignedIn,
  removeFlashMessage,
  showFooter = true,
  isChallenge = false,
  block,
  superBlock,
  theme,
  showCodeAlly,
  user,
  fetchUser,
  updateAllChallengesInfo,
  openResetPasswordModal,
}: DefaultLayoutProps): JSX.Element {
  // console.log(props.location)
  const { t } = useTranslation();
  const { challengeEdges, certificateNodes } = useGetAllBlockIds();
  useEffect(() => {
    // componentDidMount
    updateAllChallengesInfo({ challengeEdges, certificateNodes });
    if (!isSignedIn) {
      fetchUser();
      console.log("fetching user")
      // while (fetchState.pending) {
      //   // wait for the user to be fetched
      //   console.log("fetching user -- wait")
      // }
      // console.log("user fetched")
      // if (fetchState.complete && !isSignedIn) {
      //   navigate('/');
      // }
      // else if (fetchState.complete && isSignedIn) {
      //   if (user.needsPasswordReset) {
      //     navigate('/reset-password');
      //   }
      // }
      // else if (fetchState.errored && !isSignedIn) {
      //   navigate('/');
      // }

    }
    
    // if (!cookies.get('jwt_access_token') && checkLocation(location.pathname)){ 
    //   navigate('/');
    // }


    window.addEventListener('online', updateOnlineStatus);
    window.addEventListener('offline', updateOnlineStatus);

  
    return () => {
      // componentWillUnmount.
      window.removeEventListener('online', updateOnlineStatus);
      window.removeEventListener('offline', updateOnlineStatus);
    };

    
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // listen for fetchState pending and complete
  useEffect(() => {

    if (fetchState.complete && isSignedIn) {
      if (user.needsPasswordReset) {
        openResetPasswordModal();
      }
    }
  }, [fetchState.complete, isSignedIn, user.needsPasswordReset]);

  // listen for user not signed in, redirect to home
  useEffect(() => {
    if (!fetchState.pending && !isSignedIn) {
      navigate('/');
    }
  }, [isSignedIn, fetchState.pending]);

  //check if user is signed in and is admin
  useEffect(() => {
    if (isSignedIn && location.pathname =="/" && user.role === 'admin' || user.role === 'instructor' || user.role === 'manager') {
      navigate('/admin');
    }
  }, [isSignedIn, user.role]);


  const updateOnlineStatus = () => {
    const isOnline =
      isBrowser() && 'navigator' in window ? window.navigator.onLine : null;
    return typeof isOnline === 'boolean' ? onlineStatusChange(isOnline) : null;
  };

  const useSystemTheme = fetchState.complete && isSignedIn === false;
  
  if (fetchState.pending) {
    return <Loader fullScreen={true} messageDelay={5000} />;
  } else {
    return (
      <div className='page-wrapper'>
        {envData.deploymentEnv === 'staging' &&
          envData.environment === 'production' && <StagingWarningModal />}
        <Helmet
          bodyAttributes={{
            class: useSystemTheme
              ? getSystemTheme()
              : `${String(theme) === 'night' ? 'dark' : 'light'}-palette`
          }}
          meta={[
            {
              name: 'description',
              content: t('metaTags:description')
            },
            { name: 'keywords', content: t('metaTags:keywords') }
          ]}
        >
          <link
            as='font'
            crossOrigin='anonymous'
            href={latoRegularURL}
            rel='preload'
            type='font/woff'
          />
          <link
            as='font'
            crossOrigin='anonymous'
            href={latoLightURL}
            rel='preload'
            type='font/woff'
          />
          <link
            as='font'
            crossOrigin='anonymous'
            href={latoBoldURL}
            rel='preload'
            type='font/woff'
          />
          <link
            as='font'
            crossOrigin='anonymous'
            href={hackZeroSlashRegularURL}
            rel='preload'
            type='font/woff'
          />
          <link
            as='font'
            crossOrigin='anonymous'
            href={hackZeroSlashBoldURL}
            rel='preload'
            type='font/woff'
          />
          <link
            as='font'
            crossOrigin='anonymous'
            href={hackZeroSlashItalicURL}
            rel='preload'
            type='font/woff'
          />
        </Helmet>
        <div className={`default-layout`}>
          <Header
            fetchState={fetchState}
            user={user}
            skipButtonText={t('learn.skip-to-content')}
          />
          <OfflineWarning
            isOnline={isOnline}
            isServerOnline={isServerOnline}
            isSignedIn={isSignedIn}
          />
          {hasMessage && flashMessage ? (
            <Flash
              flashMessage={flashMessage}
              removeFlashMessage={removeFlashMessage}
            />
          ) : null}
          <ResetPasswordModal />
          <SignoutModal />
          {isChallenge && !showCodeAlly && !examInProgress && (
            <div className='breadcrumbs-demo'>
              <BreadCrumb
                block={block as string}
                superBlock={superBlock as string}
              />
            </div>
          )}
          {fetchState.complete && children}
        </div>
      </div>
    );
  }
}

// TODO: get challenge nodes directly rather than wrapped in edges
const useGetAllBlockIds = () => {
  const {
    allChallengeNode: { edges: challengeEdges },
    allCertificateNode: { nodes: certificateNodes }
  }: {
    allChallengeNode: AllChallengeNode;
    allCertificateNode: { nodes: CertificateNode[] };
  } = useStaticQuery(graphql`
  query getBlockNode {
    allChallengeNode(
      sort: [{challenge: {superOrder: ASC}}, {challenge: {order: ASC}}, {challenge: {challengeOrder: ASC}}]
    ) {
      edges {
        node {
          challenge {
            block
            id
          }
        }
      }
    }
    allCertificateNode {
      nodes {
        challenge {
          certification
          tests {
            id
          }
        }
      }
    }
  }
  `);

  return { challengeEdges, certificateNodes };
};

DefaultLayout.displayName = 'DefaultLayout';

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withTranslation()(DefaultLayout));
