import { call, put, takeEvery } from 'redux-saga/effects';
import { attemptLogin, getSessionUser, getUserProfile, attemptLogout } from '../utils/ajax';
import {
  fetchProfileForUserComplete,
  fetchProfileForUserError,
  fetchUserComplete,
  fetchUserError,
  loginAttemptComplete,
  loginAttemptError,
  logoutComplete,
  logoutError
} from './actions';

function* fetchSessionUser() {
  try {
    const {
      data: { user = {}, result = '' }
    } = yield call(getSessionUser);
    const appUser = user[result] || {};

    yield put(fetchUserComplete({ user: appUser, username: result }));
  } catch (e) {
    console.log('failed to fetch user', e);
    yield put(fetchUserError(e));
  }
}

function* fetchOtherUser({ payload: maybeUser = '' }) {
  try {
    const maybeUserLC = maybeUser.toLowerCase();

    const {
      data: { entities: { user = {} } = {}, result = '' }
    } = yield call(getUserProfile, maybeUserLC);
    const otherUser = user[result] || {};
    yield put(
      fetchProfileForUserComplete({ user: otherUser, username: result })
    );
  } catch (e) {
    yield put(fetchProfileForUserError(e));
  }
}

function* attemptUserLogin({ payload: {idNum = '', password = ''} }) {
  try {
    const {
      response: { status= ""},
      data: { user = {}, result = '' }
    } = yield call(attemptLogin, idNum, password);
    console.log("yield stuff: ", user, result, status)
    const appUser = user[result] || {};

    yield put(loginAttemptComplete({ user: appUser, username: result, status }));
  } catch (e) {
    yield put(loginAttemptError(e));
}
}

function* attemptUserLogout() {
  try {
    const result = yield call(attemptLogout);
    if (result) {
      yield put(logoutComplete());
    }
    else {
      yield put(logoutError());
    }
  } catch (e) {
    yield put(logoutError(e));
  }
}

export function createFetchUserSaga(types) {
  return [
    takeEvery(types.fetchUser, fetchSessionUser),
    takeEvery(types.fetchProfileForUser, fetchOtherUser),
    takeEvery(types.loginAttempt, attemptUserLogin),
    takeEvery(types.logout, attemptUserLogout)
  ];
}
