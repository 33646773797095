import { createTypes, createAsyncTypes } from '../../../utils/create-types';

export const ns = 'staffTools';

export const actionTypes = createTypes(
    [
        'changeSection',
        'openUserDetailModal',
        'closeUserDetailModal',
        'selectedUserDetail',
        ...createAsyncTypes('fetchStudents'),
        ...createAsyncTypes('fetchServerStatus'),
        ...createAsyncTypes('fetchStaff')
    ],
    ns
);