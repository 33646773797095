import { createAction } from "redux-actions";
import { actionTypes } from "./action-types";

export const changeSection = createAction(actionTypes.changeSection);

export const fetchStudents = createAction(actionTypes.fetchStudents);
export const fetchStudentsComplete = createAction(actionTypes.fetchStudentsComplete);
export const fetchStudentsError = createAction(actionTypes.fetchStudentsError);

export const fetchServerStatus = createAction(actionTypes.fetchServerStatus);
export const fetchServerStatusComplete = createAction(actionTypes.fetchServerStatusComplete);
export const fetchServerStatusError = createAction(actionTypes.fetchServerStatusError);

export const fetchStaff = createAction(actionTypes.fetchStaff);
export const fetchStaffComplete = createAction(actionTypes.fetchStaffComplete);
export const fetchStaffError = createAction(actionTypes.fetchStaffError);

export const openUserDetailModal = createAction(actionTypes.openUserDetailModal);
export const closeUserDetailModal = createAction(actionTypes.closeUserDetailModal);

export const selectedUserDetail = createAction(actionTypes.selectedUserDetail);