import { createTheme } from '@mui/material/styles'
import { createSelector } from 'reselect'
import { userSelector } from '../../redux/selectors'
import { User } from '../../redux/prop-types'
import { PaletteMode, ThemeProvider } from '@mui/material';
import React from 'react';
import { connect } from 'react-redux';

const mapStateToProps = createSelector(
    userSelector,
    (user: User) => ({
        user
    })
);

// const getDesignTokens = (mode: PaletteMode) => ({
//     palette: {
//         mode,
//         ...(mode === 'light'
//             ? {{
//                 primary: '#57a9b1'
//                   secondary: {
//                     main: '#c1e9ec'
//                   },
//                   background: {
//                     default: '#f4f6f6',
//                     paper: '#ffffff'
//                   },
//                   success: {
//                     main: '#acd157'
//                   },
//                   error: {
//                     main: '#ea2929'
//                   },
//                   text: {
//                     primary: '#1b2d32'
//                   },
//                 }
                
//             }
//             : {{
//                 primary: {
//                     main: '#57a9b1',
//                   },
//                   secondary: {
//                     main: '#c1e9ec',
//                   },
//                   background: {
//                     default: '#1b2d32',
//                     paper: '#0a1f24',
//                   },
//                   success: {
//                     main: '#acd157',
//                   },
//                   error: {
//                     main: '#ea2929',
//                   },
//                   text: {
//                     primary: '#f4f6f6',
//                   },
//             }}               
//             })
        
const getDesignTokens = (mode: PaletteMode) => ({
    palette: {
      mode,
      ...(mode === 'light'
        ? {
            // palette values for light mode
            primary: {
                main: '#57a9b1',
            },
            secondary: {
              main: '#c1e9ec',
            },
            background: {
              default: '#FFF',
              paper: '#DFE1E2',
            },
            success: {
              main: '#acd157',
            },
            error: {
              main: '#ea2929',
            },
            text: {
              primary: '#1b2d32',
            },

          }
        : {
            // palette values for dark mode
            primary: {
                main: '#57a9b1',
            },
            secondary: {
              main: '#c1e9ec',
            },
            background: {
              default: '#0A1F24',
              paper: '#0a1f24',
            },
            success: {
              main: '#acd157',
            },
            error: {
              main: '#ea2929',
            },
            text: {
              primary: '#f4f6f6',
            },
          }),
    },
  });
            
function MuiTheme({ user, children }: { user: User, children: React.ReactNode }) {
    const { theme } = user;
    const mode = theme === 'night' ? 'dark' : 'light';
    const theming = React.useMemo(()=> createTheme(getDesignTokens(mode)), [mode]);

    return (
        <ThemeProvider theme={theming}>
            {children}
        </ThemeProvider>
    );
}
    
export default connect(mapStateToProps)(MuiTheme);