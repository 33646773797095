import cookies from 'browser-cookies';
import PropTypes from 'prop-types';
import React from 'react';
import { I18nextProvider } from 'react-i18next';
import { Provider } from 'react-redux';
// import { useLocation } from '@reach/router';

import i18n from './i18n/config';
import AppMountNotifier from './src/components/app-mount-notifier';
import { createStore } from './src/redux/create-store';
import layoutSelector from './utils/gatsby/layout-selector';

const store = createStore();

export const wrapRootElement = ({ element }) => {
  return (
    <Provider store={store}>
      <I18nextProvider i18n={i18n}>
          <AppMountNotifier>{element}
          </AppMountNotifier>
      </I18nextProvider>
    </Provider>
  );
};

wrapRootElement.propTypes = {
  element: PropTypes.any
};

export const wrapPageElement = layoutSelector;

export const disableCorePrefetching = () => true;

export const onClientEntry = () => {
  // Letting the users' browsers expire the cookie seems to have caused issues
  // for some users. Until we have time to investigate further, we should remove
  // the cookie on every page load.

  cookies.erase('csrf_token');
  // const testIfSignedIn = cookies.get('jwt_access_token')
  // console.log(testIfSignedIn, window.location)
  // console.log(window.location.pathname, window.location.pathname !== '/', !window.location.pathname.startsWith('/admin'), checkLocation())
  // if (!testIfSignedIn && window.location.pathname !== '/' && !window.location.pathname.startsWith('/admin')) {
  //   window.location.assign('/')
  // }
};

const checkLocation = () => {

}

// gatsby-browser.js
export const onServiceWorkerUpdateReady = () => {
  const swUrl = `${process.env.PUBLIC_URL}/styles-404-sw.js`;
  navigator.serviceWorker.register(swUrl).then((registration) => {
    console.log('Custom Service Worker registered: ', registration);
  });
};
